<template>
    <div class="pe-record-management">
      <el-form class="zwx-form" key="peRecordListForm" ref="peRecordListForm" label-position="right" :model="peRecordListForm" @submit.native.prevent>
        <div class="condition-row">
          <el-form-item label="行政区划：" label-width="70px">
            <zwx-select-area class="cascader-area-biz-zone" ref="areaIdArea" :zoneCode12From="zoneCode12From" @loadOver="areaIdLoadOver()" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :disabled="false" :clearable="true" />
          </el-form-item>
          <el-form-item label="单位名称：" label-width="70px">
            <el-input class="zwx-input " placeholder="请输入" style="width: 200px !important;" v-model.trim="peRecordListForm.employerNameLike" maxlength="20" clearable />
          </el-form-item>
          <el-form-item label="员工姓名：" label-width="70px">
            <el-input class="zwx-input" placeholder="请输入" style="width: 200px!important;" v-model.trim="peRecordListForm.staffNameLike" clearable />
          </el-form-item>
          <el-form-item label="身份证号：" label-width="70px">
            <el-input class="zwx-input" placeholder="请输入完整身份证号" style="width: 200px!important;" v-model.trim="peRecordListForm.cardNo" clearable />
          </el-form-item>
        </div>
        <div class="condition-row" style="display:flex">
          <!-- <el-form-item label="体检编号：" label-width="70px">
            <el-input class="zwx-input" placeholder="请输入" style="width: 200px!important;"
                      v-model.trim="peRecordListForm.peCodeLike" :clearable="true"/>
          </el-form-item> -->
          <el-form-item label="体检机构：" label-width="70px">
            <el-input class="zwx-input" placeholder="请输入" style="width: 200px!important;" v-model.trim="peRecordListForm.organNameLike" :clearable="true" />
          </el-form-item>
          <el-form-item label="体检时间：" label-width="70px">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 198px !important;" v-model="peRecordListForm.peBeginDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noBigDate($data['peRecordListForm'], 'peEndDate', true)" placeholder="开始日期" />
            -
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 198px !important;" v-model="peRecordListForm.peEndDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noSmallDate($data['peRecordListForm'], 'peBeginDate', true)" placeholder="结束日期" />
          </el-form-item>
          <!-- <div class="condition-separator"/> -->
          <!-- <el-form-item label="包含未建档员工：" label-width="115px">
            <el-checkbox class="zwx-checkbox" v-model.trim="peRecordListForm.ifExistence"/>
          </el-form-item> -->
        </div>
        <div class="condition-row" style="display:flex">
          <el-form-item label="在岗状态：" label-width="70px">
            <el-checkbox-group class="zwx-checkbox-group" v-model="peRecordListForm.postStateList">
              <el-checkbox class="zwx-checkbox" v-for="item in peRecordListFormExp.postStateOption" :label="item.codeNo" :key="item.codeNo">
                {{ item.codeName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div class="condition-separator" />
          <el-form-item label="主检结论：" label-width="70px">
            <el-checkbox-group class="zwx-checkbox-group" v-model="peRecordListForm.conclusionCodeList">
              <el-checkbox class="zwx-checkbox" v-for="item in peRecordListFormExp.mainInspectionConclusionOption" :label="item.codeNo" :key="item.codeNo">
                {{ item.codeName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div class="condition-row" style="display:flex">
          <el-form-item label="体检类型：" label-width="70px">
            <el-checkbox-group class="zwx-checkbox-group" v-model="peRecordListForm.peTypeList">
              <el-checkbox class="zwx-checkbox" v-for="item in peRecordListFormExp.peTypeOption" :label="item.id" :key="item.id">
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div class="condition-separator" />
          <el-form-item label-width="70px" label="是否建档：">
            <el-checkbox-group class="zwx-checkbox-group" v-model="peRecordListForm.archivesArr">
              <el-checkbox class="zwx-checkbox" :label="0">单位未建档</el-checkbox>
              <el-checkbox class="zwx-checkbox" :label="1">员工未建档</el-checkbox>
              <el-checkbox class="zwx-checkbox" :label="2">员工已建档</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>
      <div class="independent-button-row">
        <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryPeRecordList(1)">查询</el-button>
      </div>
      <!-- 列表展示部分 -->
      <el-table ref="peRecordListTable" class="zwx-table sortable-table" row-key="rid" border stripe :data="peRecordListFormExp.tableList">
        <el-table-column prop="bizName" label="行政区划" width="180" header-align="center" align="left"></el-table-column>
        <el-table-column prop="unitName" label="单位名称" width="240" header-align="center" align="left"></el-table-column>
        <el-table-column width="120" prop="staffName" label="姓名" header-align="center" align="left">
          <!-- <template slot-scope="scope">
            {{scope.row.staffName}}
           <i :class="scope.row.staffUid != undefined && scope.row.staffUid != ''?'':'filled chis-icon-filled-overdue'" style="color:rgb(181 184 189)"></i>
          </template> -->
        </el-table-column>
        <el-table-column width="60" prop="sexStr" label="性别" header-align="center" align="center" />
        <el-table-column width="140" prop="peCode" label="体检编号" header-align="center" align="center" />
        <el-table-column width="200" prop="cardNo" label="身份证号" header-align="center" align="center" />
        <el-table-column width="230" prop="organName" label="体检机构" header-align="center" align="left" />
        <el-table-column width="120" prop="peDate" label="体检时间" header-align="center" align="center">
          <template slot-scope="scope">
            {{ scope.row.peDate != undefined && scope.row.peDate != '' ? scope.row.peDate.substring(0, 10) : scope.row.peDate }}
          </template>
        </el-table-column>
        <el-table-column prop="peType" label="体检类型" width="150" header-align="center" align="center" :formatter="peTypeFormatter" />
        <el-table-column prop="postStateStr" label="在岗状态" width="100" header-align="center" align="center" />
        <el-table-column label="主检结论" width="180" header-align="center" align="left">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.mainInspectionConclusionList" :key="item" :style="mainInspectionColor(item)">
              {{ item }}
              <i style="color:black" v-if="scope.row.mainInspectionConclusionList.length != 1 && index != scope.row.mainInspectionConclusionList.length - 1">、</i>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="危害因素" header-align="left" align="left" :min-width="240">
          <template slot-scope="scope">
            <span>{{ scope.row.hazardName || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="160" header-align="left" align="left" fixed="right">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" icon="el-icon-arrow-right" type="text" @click="detail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 分页器部分 -->
      <base-pagination class="normal-pagination" :parentPage="peRecordListForm.currentPage" :pageSize="peRecordListFormExp.pageSize" :total="peRecordListFormExp.total" @currentChange="queryPeRecordList" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'helper',
    data() {
      return {
        api: this.$store.state.api,
        users: this.$store.state.users,
        zoneCode12From: '',
        //表单对象
        peRecordListForm: {
          // 页码
          currentPage: 1,
          staffNameLike: null,
          cardNo: null,
          ifExistence: false,
          organNameLike: null,
          peCodeLike: null,
          peBeginDate: new Date().getFullYear() + '-01-01',
          peEndDate: '',
          peTypeList: [],
          conclusionCodeList: [],
          postStateList: [],
          sortType: '',
          bizZone: '',
          employerNameLike: null,
          archivesArr: [],
        },
        peRecordListFormExp: {
          //在岗状态
          postStateOption: [],
          //主检结论
          mainInspectionConclusionOption: [],
          //体检类型
          peTypeOption: [
            {
              id: 1,
              label: '职业健康体检',
            },
            {
              id: 2,
              label: '放射人员健康检查',
            },
            {
              id: 3,
              label: '福利体检',
            },
          ],
          tableList: [],
          pageSize: 18,
          total: 0,
        },
      }
    },
    created() {
      this.zoneCode12From = this.users.ifSuperAdmin ? '000000000000' : this.users.fkByUnitId.fkByZoneId.zoneCode12
    },
    mounted() {
      this.$refs.areaIdArea.initSearch(this.zoneCode12From)
      this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('3052', data => {
        this.peRecordListFormExp.mainInspectionConclusionOption = data['3052']
      })
      this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('3049', data => {
        this.peRecordListFormExp.postStateOption = data['3049']
      })
      var peBeginDate = new Date().getFullYear()
      this.peBeginDate = peBeginDate + '-01-01'
    },
    computed: {},
    methods: {
      areaIdLoadOver() {
        this.queryPeRecordList(this.peRecordListForm.currentPage)
      },
      //查询
      queryPeRecordList(currentPage) {
        this.peRecordListForm.currentPage = currentPage
        if (this.$refs.areaIdArea.getChooseArea()) {
          this.peRecordListForm.bizZone = this.$refs.areaIdArea.getChooseArea().zoneCode12
        } else {
          this.peRecordListForm.bizZone = ''
        }
        let archivesArr = [...this.peRecordListForm.archivesArr]
        let data = {
          currentPage: this.peRecordListForm.currentPage,
          staffNameLike: this.peRecordListForm.staffNameLike,
          cardNo: this.peRecordListForm.cardNo,
          peCodeLike: this.peRecordListForm.peCodeLike,
          typeList: this.peRecordListForm.peTypeList,
          organNameLike: this.peRecordListForm.organNameLike,
          peBeginDate: this.peRecordListForm.peBeginDate,
          peEndDate: this.peRecordListForm.peEndDate,
          conclusionCodeList: this.peRecordListForm.conclusionCodeList,
          postStateList: this.peRecordListForm.postStateList,
          ifExistence: this.peRecordListForm.ifExistence,
          bizZone: this.peRecordListForm.bizZone,
          employerNameLike: this.peRecordListForm.employerNameLike,
          archivesArr: archivesArr,
        }
        this.$emit('loading', true)
        this.$system.postJson(
          this.api + '/oh/health/surveillance/getAllPeRecordList-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.peRecordListFormExp.tableList = data.peRecordList
              this.peRecordListFormExp.pageSize = data.pageSize
              this.peRecordListFormExp.total = data.peRecordCount
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
            }
          },
          () => {
            this.$emit('loading', false)
            this.$notify.error({ title: '错误', message: '网络连接失败' })
          }
        )
      },
      mainInspectionColor(mainInspection) {
        if (this.$zwxBase.verifyIsNotBlank(mainInspection)) {
          if (mainInspection == '职业禁忌证') {
            return 'color:#9719BB'
          }
          if (mainInspection == '疑似职业病') {
            return 'color:#FF1A21'
          }
        }
        return '-'
      },
      peTypeFormatter(row, column, cellValue) {
        if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
          switch (cellValue) {
            case 1:
              return '职业健康体检'
            case 2:
              return '放射人员健康检查'
            case 3:
              return '福利体检'
            default:
          }
        }
        return '-'
      },
      /**
       * 查看体检记录详情
       */
      detail(row) {
        this.$router.push({
          name: 'PeRecordDetail',
          params: { employerUuid: row.employerUuid, rid: row.rid },
        })
      },
  
      /**
       * 日期格式化
       */
      dateFormatter(row, column, cellValue, index) {
        if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
          return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
        }
      },
    },
  }
  </script>
  <style lang="less" scoped>
  .pe-record-management {
    /deep/ .cascader-area-biz-zone .zwx-input {
      width: 200px !important;
    }
    .pe-record-import-dialog {
      /deep/ .organ-select .zwx-input {
        width: 398px !important;
      }
  
      .dialog-footer {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
        margin-left: 20px;
      }
  
      /deep/ .el-dialog {
        width: 520px !important;
        min-width: 520px !important;
      }
  
      /deep/ .el-upload-dragger {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 488px;
        height: 228px;
      }
  
      .el-upload-text {
        margin-top: 18px;
      }
  
      .show-file-body {
        width: 100%;
        padding: 24px;
      }
  
      .show-file-box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 102px;
        margin-bottom: 24px;
        background: #f9fbfd;
        padding-left: 36px;
        padding-right: 20px;
      }
  
      .file-name-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 72px);
        font-size: 14px;
        font-weight: 400;
        color: #1f2d3d;
        margin-left: 24px;
      }
  
      .show-error-file-box {
        display: flex;
        align-items: center;
        width: 100%;
        height: 102px;
        background: #fff5f5;
        border: 1px solid #f6cccc;
        padding-left: 36px;
        padding-right: 20px;
      }
    }
  
    /deep/ .import-record-query-dialog .el-dialog {
      width: 851px !important;
      min-width: 851px !important;
    }
  
    .import-record-query-dialog .import-record-query-body {
      height: 339px;
    }
  }
  </style>
  
  <style lang="less"></style>
  