<template>
  <div class="textBook_manage" style="padding-bottom: 20px;">
    <!-- 条件搜索部分 -->
    <el-form class="zwx-form" key="msSearchForm" ref="msSearchForm" :model="msSearchForm" label-position="right" @submit.native.prevent>
      <div class="condition-row">
        <el-form-item label="行政区划：" label-width="70px">
          <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :clearable="false" />
        </el-form-item>
        <el-form-item label="企业名称：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.userUnitName" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="订单编号：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.orderNoLike" placeholder="" clearable />
        </el-form-item>
        <!--
        <el-form-item label="文件类别：" label-width="70px">
          <el-select class="zwx-select " popper-class="zwx-select-popper" v-model="msSearchForm.fileTypeArr" multiple collapse-tags placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        -->
        <div style="clear: both" />
      </div>
      <div class="condition-row">
        <el-form-item label="机构名称：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.unitName" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="商品名称：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.goodsNames" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="交易时间：" label-width="70px">
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="msSearchForm.orderCreateTimeStart" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noBigDate($data['msSearchForm'], 'orderCreateTimeEnd', true)" placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="msSearchForm.orderCreateTimeEnd" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noSmallDate($data['msSearchForm'], 'orderCreateTimeStart', true)" placeholder="结束日期" />
        </el-form-item>
        <div style="clear: both" />
      </div>
      <div class="condition-row">
        <el-form-item label="交易状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msSearchForm.orderStateList">
            <el-checkbox class="zwx-checkbox" label="00">待支付</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="01">支付成功</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="03">退款申请</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="04">退款成功</el-checkbox>
            <el-checkbox class="zwx-checkbox" label="99">交易取消</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div style="clear: both" />
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
    </div>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="userZoneName" label="行政区划" width="150" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.userZoneName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="userUnitName" label="企业名称" min-width="220" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.userUnitName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="orderNo" label="订单编号" width="200" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.orderNo || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="goodsNames" label="商品名称" min-width="220" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.goodsNames || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="订单金额" width="90" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.totalAmount || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="orderState" label="支付状态" width="90" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ { '00': '待支付', '01': '支付成功', '03': '退款申请', '04': '退款成功', '99': '订单取消' }[row.orderState] || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="orderCreateTime" label="交易时间" width="180" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ row.orderCreateTime || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="unitName" label="机构名称" min-width="220" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.unitName || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="payPallet" label="交易渠道" width="90" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ { '04': '微信支付', '01': '支付宝', '05': '银联商务' }[row.payPallet] || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="orderDescription" label="订单描述" min-width="240" header-align="center" align="left">
        <template slot-scope="{ row }">
          {{ row.orderDescription || '-' }}
        </template>
      </el-table-column>
    </el-table>
    <base-pagination class="normal-pagination" :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="search" />
  </div>
</template>

<script>
export default {
  name: 'FinancialRecord',
  components: {},
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      //查询对象
      msSearchForm: {
        userZoneCode: '',
        userUnitName: '',
        orderNoLike: '',
        unitName: '',
        goodsNames: '',
        orderCreateTimeStart: '',
        orderCreateTimeEnd: '',
        orderStateList: ['01'],
      },
      zoneCode12From: '',
      //下方数据表格集合
      currentPage: 1,
      pageSize: 18,
      total: 0,
      dataList: [],
    }
  },
  mounted() {
    // this.search(1)
    // 赋值当前用户所属地区
    let config = window.top.vm.$store.state.config
    this.zoneCode12From = config.empower //'320200000000'
    this.$nextTick(() => {
      this.$refs.bizZoneArea.initSearch(this.zoneCode12From)
      this.$forceUpdate()
    })
  },
  activated() {
    if (this.$route.params.fresh) this.search(1)
  },
  methods: {
    areaIdLoadOver() {
      this.search(1)
    },
    bizZoneChange() {
      this.msSearchForm.userZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    //查询
    search(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      this.msSearchForm.userZoneCode = this.$refs.bizZoneArea.getChooseArea().zoneCode12
      let data = {
        currentPage: this.currentPage,
        ...this.msSearchForm,
      }
      this.$system.postJson(
        this.api + '/payment/getOrderRecordsList-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.dataList = data.resultList
            this.total = data.resultCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .zwx-input {
  width: 220px !important;
}
</style>

<style lang="less"></style>
